export default {
  pageTitle: "Ngaru Escola de Surf - Costa da Caparica, Portugal",
  header: {
    home: "Home",
    changeLangButton: "🇬🇧 English",
    cta: "Marcar aula",
    about: "Sobre nós",
    lessons: "Aulas",
    gallery: "Galeria",
    contacts: "Contactos",
    logoAlt: "Surf Ngaru",
  },
  hero: {
    title: "Ngaru",
    subtitle: "Escola de Surf",
    description:
      "À sua espera uma equipa profissional e motivada onde o prazer de estar a deslizar uma onda entre amigos é o objectivo comum",
    cta: "Marque a sua aula",
    knowMore: "Saber mais",
  },
  about: {
    title: "Sobre nós",
    description: `Se estiver na região de Setúbal, vamos ao seu encontro podemos fazer o trajecto entre o nosso ponto de encontro e a praia, pelo interior da Serra da Arrábida, ou pela belas paisagens do Sado.

Ao chegarmos à Praia da Mata na Caparica, faremos a nossa aula de Surf, no melhor ambiente, uma equipa profissional e motivada, ao seu dispor onde o prazer de estar a deslizar uma onda entre amigos é o objectivo comum.
`,
    imageAlt: "Sobre nós",
  },
  cta: {
    title: "Uma aventura onde sorrisos e diversão são garantidos",
    cta: "Marque a sua aula",
  },
  pricing: {
    title: "Aulas",
    description: "Escolha um dos nossos packs",
    cards: [
      {
        title: "Aula de grupo",
        price: 35,
        cta: "Marcar aula",
        items: [
          "Cancelamento gratuito até 24 horas de antecedência",
          "Material técnico incluído",
          "Treinadores certificados",
          "Seguro desportivo incluído",
          "Duração: 90 minutos",
        ],
      },
      {
        title: "Aula privada",
        price: 80,
        cta: "Marcar aula",
        items: [
          "Cancelamento gratuito até 24 horas de antecedência",
          "Material técnico incluído",
          "Treinadores certificados",
          "Seguro desportivo incluído",
          "Duração: 90 minutos",
        ],
      },
      {
        title: "Pack 3 aulas",
        price: 100,
        cta: "Marcar aula",
        popular: "Popular",
        items: [
          "Cancelamento gratuito até 24 horas de antecedência",
          "Material técnico incluído",
          "Treinadores certificados",
          "Seguro desportivo incluído",
          "Duração: 90 minutos",
        ],
      },
      {
        title: "Pack 6 aulas",
        price: 180,
        cta: "Marcar aula",
        items: [
          "Cancelamento gratuito até 24 horas de antecedência",
          "Material técnico incluído",
          "Treinadores certificados",
          "Seguro desportivo incluído",
          "Duração: 90 minutos",
          "Partilhável",
        ],
      },
      {
        title: "Pack 10 aulas",
        price: 250,
        cta: "Marcar aula",
        items: [
          "Cancelamento gratuito até 24 horas de antecedência",
          "Material técnico incluído",
          "Treinadores certificados",
          "Seguro desportivo incluído",
          "Duração: 90 minutos",
          "Partilhável",
        ],
      },
      {
        title: "Pack 12 aulas",
        price: 280,
        cta: "Marcar aula",
        items: [
          "Cancelamento gratuito até 24 horas de antecedência",
          "Material técnico incluído",
          "Treinadores certificados",
          "Seguro desportivo incluído",
          "Duração: 90 minutos",
          "Partilhável",
        ],
      },
    ],
  },
  gallery: {
    title: "Galeria",
    description: "Alguns dos nossos momentos",
    photoAlt: "Fotografia de surf",
  },
  testimonials: {
    title: "Testemunhos",
    description: "What our clients said about us",
  },
  contacts: {
    title: "Marque a sua aula",
    contacts: "Contactos",
    button: "Enviar mensagem",
    sending: "A enviar...",
    success: `Obrigado pela sua mensagem!
Em breve entraremos em contacto`,
    error: "Não foi possível enviar sua mensagem. Por favor, tente novamente.",
    addressLabel: "Morada",
    address: "Praia da Mata\nCosta da Caparica, Almada",
    phoneLabel: "Telefone",
    phone: "+351 938 609 185",
    email: "info@surfngaru.pt",
    emailLabel: "E-Mail",
    nameLabel: "Nome *",
    lessonsLabel: "Aulas",
    messageLabel: "Mensagem *",
    optionDefault: "Escolha um pack",
    optionGroup: "Aula de grupo",
    optionPrivate: "Aula privada",
    optionPack3: "Pack 3 aulas",
    optionPack6: "Pack 6 aulas",
    optionPack10: "Pack 10 aulas",
    optionPack12: "Pack 12 aulas",
    requiredError: "Por favor preencha os campos obrigatórios",
    errorName: "Preencha o seu nome",
    errorEmail: "Preencha o seu email",
    errorMessage: "Preencha a sua mensagem",
  },
  footer: {
    logoAlt: "Surf Ngaru",
    site: "surfngaru.pt",
    facebook: "https://www.facebook.com/EscoladeSurfNgaru/",
    instagram: "https://www.instagram.com/ngarusurfschool/",
  },
  seo: {
    title: "Ngaru Escola de Surf - Costa da Caparica, Portugal",
    description: "Escola de Surf Praia da Mata - Costa da Caparica, Portugal",
    url: "https://surfngaru.pt/",
    image: "https://surfngaru.pt/logo.png",
  },
};
